export const IEifyPlugin = ({ selector, style }) => {
  if (style.display === 'flex' && !style.flexDirection) {
    style.flexDirection = 'column';
  }

  const flexFields = ['flexGrow', 'flexShrink', 'flexBasis'];

  function updateFlex(field) {
    let flexVars = ['1', '0', 'auto'];
    if (style.flex) {
      const existingFlex = style.flex.toString().split(' ');
      flexVars = existingFlex.concat(flexVars.slice(existingFlex.length, 3));
    }

    const index = flexFields.indexOf(field);
    if (~index) {
      flexVars[index] = style[field];
    }

    style.flex = flexVars.join(' ');
    style.MsFlex = style.flex;
  }

  ['flex', ...flexFields].forEach(field => style[field] && updateFlex(field));

  [{
    key: 'flexDirection',
    msKey: 'MsFlexDirection',
  }].forEach(({ key, msKey }) => {
    if (style[key]) {
      style[msKey] = style[key];
    }
  });

  if (style.justifyContent) {
    switch (style.justifyContent) {
      case 'flex-start':
        style.MsFlexPack = 'start';
        break;
      case 'flex-end':
        style.MsFlexPack = 'end';
        break;
      case 'space-between':
        style.MsFlexPack = 'justify';
        break;
      case 'space-around':
        style.MsFlexPack = 'distribute';
        break;
      default:
        style.MsFlexPack = style.justifyContent;
        break;
    }
  }

  [{
    key: 'alignItems',
    msKey: 'MsFlexAlign',
  }, {
    key: 'alignSelf',
    msKey: 'MsFlexItemAlign',
  }, {
    key: 'alignContent',
    msKey: 'MsFlexAlign',
  }].forEach(({ key, msKey }) => {
    if (style[key]) {
      switch (style[msKey]) {
        case 'flex-start':
          style[msKey] = 'start';
          break;
        case 'flex-end':
          style[msKey] = 'end';
          break;
        default:
          style[msKey] = style[key];
          break;
      }
    }
  });

  return { selector, style };
};

export default IEifyPlugin;
