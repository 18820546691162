const defaultFont = {
  fontFamily: `Futura, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
}

export const styles = {
  cta: {
    marginTop: 24,
    background: 'white',
    borderRadius: 1,
    color: '#182f33',
    cursor: 'pointer',
    padding: '12px 36px',
    textTransform: 'none',
    transition: 'opacity 0.3s ease',
    ':hover': {
      opacity: 0.8,
    },
  },
  title: {
    alignSelf: 'center',
    color: 'white',
    display: 'inline-flex',
    flexDirection: 'column',
    fontSize: '2.4rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    ...defaultFont
  },
  text: {
    ...defaultFont,
  }
};

export default styles;
