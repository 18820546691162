import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';

import { Text } from "./text.component";
import { View } from "./view.component";

import { styles as defaultStyles } from '../styles';

import close from './close.svg';
import './learn-more.component.css';

const title = "Bringing mental health to those who need it most";

const copy = `
 Arya is working to bring mental health resources to underserved populations in low resource communities.
 We are inspired by the resilience and strength of those who have survived war, natural disaster, violence, and poverty and we want to help survivors as they rebuild their lives.
 We are focused on harboring the strength of community and using technology and to meet them where they are, at scale.
`;

const secondCopy = `Read more about the problem we’re solving`;

const styles = {
  cta: {
    ...defaultStyles.cta,
  },
  ctaContainer: {
    paddingTop: 24,
  },
  inner: {
    padding: '20vh 10vw',
    flex: 1,
    flexDirection: 'column',
    '@media(max-width: 600px)': {
      padding: '20vh 2vw',
    },
  },
  link: {
    color: '#5cb6c3',
    textDecoration: 'underline',
  },
  overlay: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#182f33',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  title: {
    ...defaultStyles.title,
    textTransform: 'uppercase',
    maxWidth: 600,
    flexShrink: 0,
  },
  copy: {
    ...defaultStyles.text,
    paddingTop: 24,
    color: '#6D8285',
    maxWidth: 600,
    alignSelf: 'center',
    margin: 'auto',
    flexShrink: 0,
  }
};

export class LearnMore extends Component {
  render() {
    return (
      <View classes={styles.overlay}>
        <View classes={styles.inner}>
          <Text classes={styles.title}>{title}</Text>
          <Text classes={styles.copy}>{copy}</Text>
          {/* <a
            href="#"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text classes={css(styles.copy, styles.link)}>
              {secondCopy}
            </Text>
          </a> */}
          <View classes={styles.ctaContainer}>
            <a
              href="https://goo.gl/forms/2zQSgtnWUCE6zmD43"
              rel="noopener noreferrer"
              target="_blank"
            >
              <View classes={styles.cta} inline>
                Get involved
              </View>
            </a>
          </View>
        </View>
        <Link to="/">
          <img className="close" src={close} alt="logo" />
        </Link>
      </View>
    );
  }
}

export default LearnMore;
