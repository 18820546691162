
import React, { PureComponent } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { plugins } from 'glamor';
import bowser from 'bowser';

import { Home } from './home.component';
import { LearnMore } from './learn-more.component';

import { IEifyPlugin } from '../utils/glamor.utils';

if (bowser.msie) {
  plugins.add(IEifyPlugin);
}

export class App extends PureComponent {
  render() {
    return (
      <Router>
        <div>
          <Route path="/" exact component={Home} />
          <Route path="/learn-more" exact component={LearnMore} />
        </div>
      </Router>
    );
  }
}

export default App;
