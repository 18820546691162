import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Text } from './text.component';
import { View } from './view.component';
import { css } from 'glamor';

import linkedin from '../linkedin.svg';
import medium from '../medium.png';
import mail from '../mail.png';

import { styles as defaultStyles } from '../styles';

const styles = {
  border: {
    borderWidth: 3,
    borderColor: 'white',
    borderStyle: 'solid',
    padding: '8px 24px',
  },
  cta: {
    ...defaultStyles.cta,
  },
  container: {
    height: '100%',
    flexWrap: 'wrap',
  },
  column: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: '40vh',
    minWidth: 300,
    '@media(max-width: 600px)': {
      paddingTop: '10vh',
    },
  },
  link: {
    cursor: 'pointer',
    margin: 20,
    width: 24, height: 24
  },
  title: {
    ...defaultStyles.title
  },
  subtitle: {
    fontSize: '1rem',
  },
};

export class Home extends Component {
  render() {
    return (
      <View classes={styles.container} row>
        <View classes={styles.column}>
          <Text
            classes={css(
              styles.title,
              styles.border
            )}
          >
            Arya
          </Text>
        </View>
        <View classes={styles.column}>
          <View>
            <Text classes={styles.title}>
              From Surviving
            </Text>
            <Text classes={styles.title}>
              To Thriving
            </Text>
          </View>
          <Link to="/learn-more">
            <View classes={styles.cta}>
              Learn more
            </View>
          </Link>
          <View row>
            <View classes={styles.link}>
              <a
                href="https://www.linkedin.com/in/nisaqais/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{width: 24, height: 24}}
                  src={linkedin}
                  target="_blank"
                />
              </a>
            </View>
            <View classes={styles.link}>
              <a
                href="https://medium.com/@nisa_10453"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{width: 24, height: 24}}
                  src={medium}
                />
              </a>
            </View>
            <View classes={styles.link}>
              <a
                href="mailto:nisa.qais@gmail.com?subject=Interest in Arya"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{width: 24, height: 24}}
                  src={mail}
                />
              </a>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default Home;
