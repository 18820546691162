import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'glamor';

/**
 * The only true view.
 */
// https://github.com/facebook/css-layout#default-values
const styles = {
  default: css({
    fontFamily: '"Open Sans", sans-serif',
    alignItems: 'stretch',
    borderWidth: 0,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 0,
    flexDirection: 'column',
    MsFlexDirection: 'column',
    /* fix flexbox bugs */
    minHeight: 0,
    minWidth: 0,
  }),
  row: css({
    flexDirection: 'row',
    MsFlexDirection: 'row',
  }),
  inline: css({
    display: 'inline-flex',
  }),
};

export class View extends Component {
  static displayName = 'View';

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { row, inline, classes, ...other } = this.props;

    return (
      <div {...css(styles.default, row && styles.row, inline && styles.inline, classes)} {...other}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * @component
 */
export default View;
