import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import View from './view.component';

const styles = {
  singleLine: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  onClick: {
    cursor: 'pointer',
  },
  selectable: {
    userSelect: 'none',
  },
  bold: {
    fontWeight: 800,
  },
};

export class Text extends Component {
  static displayName = 'Text';

  static propTypes = {
    children: PropTypes.node,
    /** Truncates the text with an ellipsis after this many lines. Currently only supports `1`. */
    numberOfLines: PropTypes.number,
    /** When false, the text is not selectable. */
    selectable: PropTypes.bool,
  };

  static defaultProps = {
    selectable: true,
  };

  render() {
    const {
      classes,
      numberOfLines,
      onClick,
      selectable,
      type,
      ...other
    } = this.props;

    return (
      <View
        classes={css(
          numberOfLines === 1 && styles.singleLine,
          !selectable && styles.selectable,
          onClick && styles.onClick,
          classes,
        )}
        {...other}
      >
        {this.props.children}
      </View>
    )
  }
}

export default Text;
